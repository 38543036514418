import router from "@/router";
import store from "@/store/store";

export function errorHandler(error) {
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            store.dispatch("tryAutoLogin");
            if (store.getters.estaAutenticado) {
                router.push("/naoautorizado");
            } else {
                router.push("/login");
            }
        } else if (error.response.status === 400) {
            let errorData = error.response.data;
            if (errorData.exception) {
                store.dispatch("setRequestError", errorData.message);
                router.push("/error");
            } else {
                return obterListaErros(errorData.errors);
            }
        } else {
            store.dispatch("setRequestError", error.response);
            router.push("/error");
        }
    } else if (error.request) {
        store.dispatch("setRequestError", error.request);
        router.push("/error");
    } else {
        console.log(error.message);
    }
}

function obterListaErros(errors) {
    let errorsLista = [];
    Object.entries(errors).forEach(([, value]) => {
        errorsLista.push(`${value}`);
    });
    return errorsLista;
}
